import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useUser } from '../../contexts/UserContext';
import { useEvent } from '../../contexts/EventContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as customQuery from '../../graphql/custom_queries';

import { v4 as uuidv4 } from 'uuid';


const UserEventList = () => {
    const navigate = useNavigate();
    const { userDynamoData, loading, error } = useUser();
    const { events, currentEvent, setCurrentEvent } = useEvent();
    const [eventLength, setEventLength] = useState({});
    const [createNewEvent, setCreateNewEvent] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const eventListRef = useRef(null);

    console.log('Events:', events);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollTop = eventListRef.current.scrollTop;
    //         if (scrollTop > 0 && !isScrolled) {
    //             setIsScrolled(true);
    //         } else if (scrollTop === 0 && isScrolled) {
    //             setIsScrolled(false);
    //         }
    //     };

    //     const listElement = eventListRef.current;
    //     listElement.addEventListener('scroll', handleScroll);

    //     return () => {
    //         listElement.removeEventListener('scroll', handleScroll);
    //     };
    // }, [isScrolled]);

    useEffect(() => {
        const updateEventLength = async () => {
          const newEventLength = {};
    
          // Iterate through events and fetch photo lengths
          for (const event of events) {
            const photosLength = await fetchPhotosForEvent(event.id);
            newEventLength[event.id] = photosLength;
          }

    
          // Update state with the new eventLength object
          setEventLength(newEventLength);
        };
    
        updateEventLength();
      }, [events]); // Trigger the effect whenever events change
    
    console.log('Event length:', eventLength)

    // Fetch photos for a specific event
    const fetchPhotosForEvent = async (eventId) => {
        try {
            const response = await API.graphql(graphqlOperation(customQuery.listPhotosWithUsers, {
                filter: {
                    eventID: {
                        eq: eventId
                    }
                }
            }));

            const photos = response.data.listPhotos.items;
            
            // Filter photos to only include those that belong to the current user
            const userPhotos = photos.filter(photo => photo.users.items.some(item => item.userID === userDynamoData.id));
            const userPhotoLength = userPhotos.length;

            return userPhotoLength;
        } catch (error) {
            console.error("Error fetching photos for event:", error);
            return 0;
        }
    }

    const handleEventClick = (event) => {
        setCurrentEvent(event);
        // Navigate to event page or any additional logic...
      };

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.25,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.25,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.33,
              slidesToScroll: 1,
            },
          },
        ],
    };
    

    const truncateEventTitle = (title) => {
        const maxLength = 25;
        return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
    };

    if (loading) return(<div className="bg-black min-h-screen"><p>Loading...</p></div>);
    if (error) return <p>Error: {error.message}</p>;

    if (createNewEvent == true) {
        console.log('creating new event');
        const currentDtm = new Date().toISOString();
        const eventID = uuidv4();
        // create new event
        const eventDetails = {
            id: eventID,
            name: "Halloween Party 2018",
            coverPhotoURL: "",
            date: "2018-10-27",
            location: "New York, NY",
            hostID: userDynamoData.id,
            createdAt: currentDtm,
            updatedAt: currentDtm
        }
        API.graphql(graphqlOperation(mutations.createEvent, {input: eventDetails}))
            .then(response => {
                console.log('Create Eventresponse:', response);
                setCreateNewEvent(false);
            })
            .catch(error => {
                console.log('Create Event error:', error);
            });

        const userEventJoinDetails = {
            userID: userDynamoData.id,
            eventID: eventID
        };

        API.graphql(graphqlOperation(mutations.createUserEventJoin, {input: userEventJoinDetails}))
            .then(response => {
                console.log('Create User Event Join response:', response);
            })
            .catch(error => {
                console.log('Create User Event Join error:', error);
            });
    }

    return (
        <div className="max-h-screen min-h-screen mx-auto overscroll-none bg-white text-darkgray">
            {/* New Events (user has not looked at event photos or event has new photos uploaded) */}
            <div className="container mx-auto px-4 pt-8">
                <h1 className="font-medium text-2xl mb-4">✨ New Events ✨</h1>
                {events.length === 0 ? (
                <div className="font-bold text-lg">You have no events.</div>
                ) : (
                <Slider {...settings}>
                    {events.map((event) => (
                    <div key={event.id} className="h-auto">
                        <div
                        onClick={() => handleEventClick(event)}
                        className="cursor-pointer hover:bg-gray-200 w-5/6 rounded-md"
                        >
                            <img
                                src={event.coverPhotoURL}
                                className="object-cover w-full h-40 rounded-md mb-2"
                                alt={`Event cover photo for ${event.name}`}
                            />
                            <p className="text-md font-semibold mb-1">{event.name}</p>
                            <p className="text-sm font-light text-gray-500">
                                {event.location} · {event.startDate} · {eventLength[event.id]} photos
                            </p>
                        </div>
                    </div>
                    ))}
                </Slider>
                )}
            </div>

            {/* Recently Visited Events (keep running log of all events user visited) */}
            <div className="container mx-auto px-4 pt-4">
                <h1 className="font-medium text-2xl mb-4">🤩 Favorites 🤩</h1>
                {events.length === 0 ? (
                <div className="font-bold text-lg">You have no events.</div>
                ) : (
                <Slider {...settings}>
                    {events.map((event) => (
                    <div key={event.id} className="h-auto">
                        <div
                        onClick={() => handleEventClick(event)}
                        className="cursor-pointer hover:bg-gray-200 w-5/6 rounded-md"
                        >
                            <img
                                src={event.coverPhotoURL}
                                className="object-cover w-full h-40 rounded-md mb-2"
                                alt={`Event cover photo for ${event.name}`}
                            />
                            <p className="text-md font-semibold mb-1">{event.name}</p>
                            <p className="text-sm font-light text-gray-500">
                                {event.location} · {event.startDate} · {eventLength[event.id]} photos
                            </p>
                        </div>
                    </div>
                    ))}
                </Slider>
                )}
            </div>

            {/* Recently Visited Events (keep running log of all events user visited) */}
            <div className="container mx-auto px-4 pt-4">
                <h1 className="font-medium text-2xl mb-4">💫 Recently Visited Events 💫</h1>
                {events.length === 0 ? (
                <div className="font-bold text-lg">You have no events.</div>
                ) : (
                <Slider {...settings}>
                    {events.map((event) => (
                    <div key={event.id} className="h-auto">
                        <div
                        onClick={() => handleEventClick(event)}
                        className="cursor-pointer hover:bg-gray-200 w-5/6 rounded-md"
                        >
                            <img
                                src={event.coverPhotoURL}
                                className="object-cover w-full h-40 rounded-md mb-2"
                                alt={`Event cover photo for ${event.name}`}
                            />
                            <p className="text-md font-semibold mb-1">{event.name}</p>
                            <p className="text-sm font-light text-gray-500">
                                {event.location} · {event.startDate} · {eventLength[event.id]} photos
                            </p>
                        </div>
                    </div>
                    ))}
                </Slider>
                )}
            </div>

        </div>
    );
};

export default UserEventList;
//   useEffect(() => {
//     // get list of eventIDs from event items
//     const eventIDs = userDynamoData.events.items.map((event) => {
//         return event.eventID;
//     });

//     const loadEvents = async () => {
//         try{
//             const fetchEventByID = async (eventID) => {
//                 console.log(eventID);
//                 const eventData = await API.graphql(graphqlOperation(queries.getEvent, { id: eventID }));
//                 return eventData.data.getEvent;
//               };
              
//             const events = await Promise.all(eventIDs.map(fetchEventByID));

//             setEvents(events);
//         } catch (error) {
//             console.log('error:', error);
//         }
//     };

//     if (events.length === 0) {
//         loadEvents();
//     }
//   }, [events]);
// if (events.length === 0) {
//     console.log('No events');
//     return (
//     <div className="flex flex-col items-center space-y-4 md:space-y-6">
//         <p>You have no events</p>
//     </div>
//     );
// }
