export const listPhotosWithUsers = /* GraphQL */ `
  query ListPhotos(
    $id: ID
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhotos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          items {
            id
            photoID
            userID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;