import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

import { useUser } from './UserContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

// 1. Create the context and export it
export const EventContext = createContext();

// 2. Create the custom hook for easy access
export const useEvent = () => {
    return useContext(EventContext);
};

// 3. Create the Provider component
export const EventProvider = ({ children }) => {
    const { userDynamoData } = useUser();
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState(null);
    const previousEventDataRef = useRef();
    
    useEffect(() => {
        if (userDynamoData) {
            // If no previous data OR user changes OR event count changes, then fetch.
            if (!previousEventDataRef.current || 
                previousEventDataRef.current.userID !== userDynamoData.id ||
                (previousEventDataRef.current.events && userDynamoData.events &&
                 previousEventDataRef.current.events.length !== userDynamoData.events.length)) {

                // get list of eventIDs from event items
                const eventIDs = userDynamoData.events.items.map((event) => {
                    return event.eventID;
                });
            
                const loadEvents = async () => {
                    try{
                        const fetchEventByID = async (eventID) => {
                            // console.log(eventID);
                            const eventData = await API.graphql(graphqlOperation(queries.getEvent, { id: eventID }));
                            return eventData.data.getEvent;
                        };
                        
                        const events = await Promise.all(eventIDs.map(fetchEventByID));
            
                        setEvents(events);
                    } catch (error) {
                        console.log('error:', error);
                    }
                };
                // console.log('Loading events!');
                loadEvents();
                // console.log('Events loaded!');
            }
            previousEventDataRef.current = userDynamoData; 
        }
    }, [userDynamoData]);

    // Provide user, loading, and error states to components
    const contextValue = {
        events,
        setEvents,
        currentEvent,
        setCurrentEvent
    };

    return (
        <EventContext.Provider value={contextValue}>
            {children}
        </EventContext.Provider>
    );
};
