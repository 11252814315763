import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import Webcam from "react-webcam";
import AWS from 'aws-sdk';
import { graphqlOperation, API } from 'aws-amplify';
// import function to read from User table in DynamoDB
import * as mutations from "../../graphql/mutations";
import '../../stylesheets/webcam/webcam.css';

// import buffer
import { Buffer } from 'buffer';

const WebcamComponent = ({ onStartClick }) => {
    const navigate = useNavigate();

    const { user, userDynamoData, loading, error } = useUser();
    
    const webcamRef = useRef(null);
    
    const [isStarted , setIsStarted] = useState(false);
    const [image, setImage] = useState('');

    // get user info from UserContext
    

    const capture = React.useCallback( () => {
            // Set image from webcam capture
            const image = webcamRef ?.current ?.getScreenshot();
                
            if (image) {
                setImage(image);
                console.log('Set image successfully...');
            }
        },
        [webcamRef]
    );

    function getCurrentDateTime() {
        // JavaScript's getMonth() function starts at 0 (January is 0, December is 11), so we add 1
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        // Construct the formatted string
        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
      }
      
    
    // Send captured image to user registry S3 bucket and update DynamoDB User table with profile pic
        // image to user registry S3 bucket triggers Lambda function to index face in Rekognition collection
    async function sendImage(e) {
        e.preventDefault();
        console.log('sendImage FUNCTION START');

        console.log('userDynamoData: ', userDynamoData);
        // get current user info
        const user_id = userDynamoData.id;

        // get current date and time
        const currentDateTime = getCurrentDateTime();
    
        // create S3 object
        const s3 = new AWS.S3();

        // send image to user registry S3 bucket
        const newuser_filepath = user_id + '_' + currentDateTime + '.jpeg';
        
        // convert image to buffer
        const image_buff = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""),'base64');

        // set params for S3 upload
        const newuser_params = {
            Bucket: 'testcoreapp-userregistry',
            Key: newuser_filepath,
            Body: image_buff,
            ContentType: 'image/*',
            Metadata: {
                'owner_id': user_id,
            }
        };

        // upload image to S3
        s3.upload(newuser_params, function(err, data) {
            if (err) {
                console.log('Error uploading image: ', err);
            } else {
                console.log('Successfully uploaded image on S3', data);
            }
        });

        // update DynamoDB User table with profile pic
        const profile_pic_url = 'https://testcoreapp-userregistry.s3.us-west-2.amazonaws.com/' + newuser_filepath;

        const updateUserInput = {
            id: user_id,
            profilePicture: profile_pic_url,
        };

        console.log('updateUser: ', updateUserInput);

        try {
            const data = await API.graphql(graphqlOperation(mutations.updateUser, {input: updateUserInput}));
            console.log('data from updateUser: ', data);
        } catch (err) {
            console.log('error from updateUser: ', err);
        }
        

        console.log('sendImage FUNCTION END');

        // route to /nameEntry if user has no name or blank name
        // route to firstVisitedEventPage if present in localStorage
        // route to /events if user has name
        if (userDynamoData.name === null || 
                userDynamoData.name === undefined ||
                userDynamoData.name === "") {
            console.log('User has no name, routing to name entry...');
            navigate('/nameEntry');

        } else if (localStorage.getItem('firstVisitedEventPage')) {
            const item = localStorage.getItem('firstVisitedEventPage');
            console.log('firstVisited Event Page: ',item);

            let event_path = item.replace('http://localhost:3000','');
            event_path = item.replace('https://www.trytelescopeapp.com/','');
    
            console.log('Event path: ', event_path)

        } else {
            navigate('/events');
        }
      };

    // Reset image state
    function resetImage() {
        setImage('');
    };


    return (
        // display webcam if image is empty, otherwise display image
        <div className="flex items-center justify-center">
            {image ? (
                <div className="rounded-lg w-400px h-screen p-6">
                    <img src={image} alt="Captured" className="rounded-lg object-cover w-full h-4/6 flex-grow" />
                    <br></br>
                    <br></br>
                    <button 
                        className='bg-sec-yellow text-black hover:bg-yellow-600 transition w-[300px] py-2 rounded' 
                        onClick={sendImage}>Looks great!
                    </button>
                    <br></br>
                    <br></br>
                    <button 
                        className='text-sec-yellow hover:underline transition w-[50px] py-2 rounded' 
                        onClick={resetImage}>Try again
                    </button>
                </div>
            ) : (
                <div className="rounded-lg w-400px h-700px p-6 overflow-hidden overscroll-none flex flex-col">
                    <Webcam
                        className="rounded-lg object-cover w-full h-4/6 flex-grow"
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                    />
                    <br></br>
                    <div className='flex flex-col items-center'> 
                        <button 
                            className='bg-sec-yellow text-black hover:bg-yellow-600 transition w-250px py-2 rounded' 
                            onClick={capture}> Capture photo
                        </button>
                    </div>
                    <br></br>
                    <button 
                        className='text-sec-yellow hover:underline transition w-[50px] py-2 rounded' 
                        onClick={onStartClick}> Back
                    </button>
                </div>
            )}
        </div>
    );
};

export default WebcamComponent;
