import React, { useContext } from 'react';
import EventPage from './EventPage';
import UnauthEventView from './UnauthEventView';
import { UserContext } from '../../contexts/UserContext'; // Assuming you have a context from UserProvider

const EventWrapper = () => {
    const { user } = useContext(UserContext); // Assuming user context has a user object or similar to determine if a user is authenticated

    console.log(user);

    if (user) {
        return <EventPage />;
    } else {
        console.log('Unauthenticated user');
        return <UnauthEventView />;
    }
}

export default EventWrapper;