import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase-config'; 
import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

// 1. Create the context and export it
export const UserContext = createContext();

// 2. Create the custom hook for easy access
export const useUser = () => {
    return useContext(UserContext);
};

// 3. Create the Provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userDynamoData, setUserDynamoData] = useState(null);
    const [loading, setLoading] = useState(true);  // Feedback: loading state
    const [error, setError] = useState(null);      // Error handling

    //// update user and userDynamo data when user logs in ////
    useEffect(() => {
        const initializeUserState = () => {
            const unsubscribe = auth.onAuthStateChanged(async loggedInUser => {
                setUser(loggedInUser);
                setLoading(true);
                // directly using loggedInUser here
                if (loggedInUser) {
                    if (userDynamoData) {
                        setLoading(false);
                    } else {
                        const detailedUserData = await fetchUserDataFromDynamoDB(loggedInUser);
                        setUserDynamoData(detailedUserData);
                        console.log('UserDynamoData set:', detailedUserData);
                        setLoading(false);
                    }
                } else {
                    console.log('Setting UserDynamoData to NULL...');
                    setUserDynamoData(null); // Clear userData when user logs out
                    setLoading(false);
                }
            }, authError => {
                setError(authError);
                setLoading(false);
            });
        
            return () => unsubscribe();
        };
        
        initializeUserState();
        // Removing user from the dependency array
    }, []);  

    // // 1. Effect to listen for authentication status changes
    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged(loggedInUser => {
    //         setUser(loggedInUser);
    //         if (!loggedInUser) {
    //             setUserDynamoData(null); // Clear userData when user logs out
    //         }
    //     }, authError => {
    //         setError(authError);
    //     });

    //     return () => unsubscribe();
    // }, []);

    // // 2. Effect to fetch user data from DynamoDB whenever the user changes
    // useEffect(() => {
    //     const fetchUserDetails = async () => {
    //         setLoading(true);
    //         if (user) {
    //             const detailedUserData = await fetchUserDataFromDynamoDB(user);
    //             setUserDynamoData(detailedUserData);
    //             setLoading(false); // Loading is set to false only after userData is fetched or an error occurred
    //         } else {
    //             setLoading(false);
    //         }
    //     };
        
    //     if (user) {
    //         fetchUserDetails();
    //     }
    // }, [user]);  // <-- Only fetch user details when the user state changes
    
    // const fetchUserDetails = async () => {
    //     setLoading(true);
    //     if (user) {
    //         const detailedUserData = await fetchUserDataFromDynamoDB(user);
    //         setUserDynamoData(detailedUserData);
    //         console.log('UserDynamoData set:', detailedUserData);
    //         setLoading(false); // Loading is set to false only after userData is fetched or an error occurred
    //     } else {
    //         setUserDynamoData(null); // Clear userData when user logs out
    //         setLoading(false);
    //     }
    // };

    // function to fetch user data from DynamoDB
    const fetchUserDataFromDynamoDB = async (user) => {
        console.log('Fetching user data for:', user);

        if (userDynamoData) {
            return userDynamoData;
        }

        console.log('User Dynamo:',userDynamoData);

        try {
            const findUserResponse = await API.graphql(graphqlOperation(queries.getUser, { id: user.uid }));
            console.log('FindUser response:', findUserResponse);

            // if user not founc, create new user
            if (!findUserResponse.data.getUser) {
                console.log('User not found, creating new user...');
                const currentDtm = new Date().toISOString();
                const newUserData = {
                    id: user.uid,
                    phoneNumber: user.phoneNumber,
                    createdAt: currentDtm,
                    updatedAt: currentDtm
                }
                const newUserResponse = await API.graphql(graphqlOperation(mutations.createUser, { input: newUserData }));
                console.log('CreateUser response:', newUserResponse);
                return newUserResponse.data.createUser;
            }
            return findUserResponse.data.getUser;
        } catch (error) {
            console.error('Error fetching user data:', error);
            console.log('User not found, creating new user???');
            // throw error;  // Rethrow the error so it can be caught and handled by the calling function
        }       
    };

    // Provide user, loading, and error states to components
    const contextValue = {
        user,
        userDynamoData,
        loading,
        setLoading,
        fetchUserDataFromDynamoDB,
        error
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};
