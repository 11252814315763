import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useUser } from '../../contexts/UserContext';
import { useEvent } from '../../contexts/EventContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as customQuery from '../../graphql/custom_queries';

import { v4 as uuidv4 } from 'uuid';


const UserEventList = () => {
    const navigate = useNavigate();
    const { userDynamoData, loading, error } = useUser();
    const { events, currentEvent, setCurrentEvent } = useEvent();
    const [eventLength, setEventLength] = useState({});
    const [createNewEvent, setCreateNewEvent] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const eventListRef = useRef(null);

    console.log('Events:', events);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollTop = eventListRef.current.scrollTop;
    //         if (scrollTop > 0 && !isScrolled) {
    //             setIsScrolled(true);
    //         } else if (scrollTop === 0 && isScrolled) {
    //             setIsScrolled(false);
    //         }
    //     };

    //     const listElement = eventListRef.current;
    //     listElement.addEventListener('scroll', handleScroll);

    //     return () => {
    //         listElement.removeEventListener('scroll', handleScroll);
    //     };
    // }, [isScrolled]);

    useEffect(() => {
        const updateEventLength = async () => {
          const newEventLength = {};
    
          // Iterate through events and fetch photo lengths
          for (const event of events) {
            const photosLength = await fetchPhotosForEvent(event.id);
            newEventLength[event.id] = photosLength;
          }

    
          // Update state with the new eventLength object
          setEventLength(newEventLength);
        };
    
        updateEventLength();
      }, [events]); // Trigger the effect whenever events change
    
    console.log('Event length:', eventLength)

    // Fetch photos for a specific event
    const fetchPhotosForEvent = async (eventId) => {
        try {
            const response = await API.graphql(graphqlOperation(customQuery.listPhotosWithUsers, {
                filter: {
                    eventID: {
                        eq: eventId
                    }
                }
            }));

            const photos = response.data.listPhotos.items;
            
            // Filter photos to only include those that belong to the current user
            const userPhotos = photos.filter(photo => photo.users.items.some(item => item.userID === userDynamoData.id));
            const userPhotoLength = userPhotos.length;

            return userPhotoLength;
        } catch (error) {
            console.error("Error fetching photos for event:", error);
            return 0;
        }
    }

    const handleEventClick = (event) => {
        setCurrentEvent(event);
        // Navigate to event page or any additional logic...
      };

    const truncateEventTitle = (title) => {
        const maxLength = 25;
        return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
    };

    if (loading) return(<div className="bg-black min-h-screen"><p>Loading...</p></div>);
    if (error) return <p>Error: {error.message}</p>;

    if (createNewEvent == true) {
        setCreateNewEvent(false);
        console.log('creating new event');
        const currentDtm = new Date().toISOString();
        const eventID = uuidv4();
        // create new event
        const eventDetails = {
            id: eventID,
            name: "Carina & Daniel's Wedding",
            coverPhotoURL: "",
            date: "2023-01-07",
            startDate: "January 7, 2023",
            location: "Jerez de la Frontera, Cádiz, Spain",
            hostID: userDynamoData.id,
            createdAt: currentDtm,
            updatedAt: currentDtm
        }
        API.graphql(graphqlOperation(mutations.createEvent, {input: eventDetails}))
            .then(response => {
                console.log('Create Eventresponse:', response);
                

                const userEventJoinDetails = {
                    userID: userDynamoData.id,
                    eventID: eventID
                };
        
                API.graphql(graphqlOperation(mutations.createUserEventJoin, {input: userEventJoinDetails}))
                    .then(response => {
                        console.log('Create User Event Join response:', response);
                    })
                    .catch(error => {
                        console.log('Create User Event Join error:', error);
                    });
            })
            .catch(error => {
                console.log('Create Event error:', error);
            });

        
    }

    return (
        <div className='max-h-screen min-h-screen mx-auto overscroll-none bg-white bg-opactiy-50 text-darkgray flex justify-center'>
            <div style={{width:'1280px'}}>
                <div className='flex flex-col items-left px-4 pt-4 space-y-6 sm:space-y-4'>
                    <h1 className='font-medium'>Your events</h1>
                    <p className='p-0 m-0 font-light'>Check out the events you've attended</p>
                    <hr></hr>
                </div>
                <div
                    ref={eventListRef}
                    className={`overflow-y-auto overflow-scroll ${isScrolled ? 'bg-opacity-70 bg-white' : 'bg-inherit'}`}>
                        {events.length === 0 ? (
                            <div className='p-4 font-bold text-3xl'>You have no events.</div>
                        ) : (
                            <ul 
                                className="grid grid-cols-1 gap-4 px-4 py-2
                                md:grid-cols-2
                                lg:grid-cols-3
                                xl:grid-cols-3" 
                                style={{ alignItems: 'baseline' }}
                            >
                                {events.map((event, index) => (
                                    <li
                                        key={event.id}
                                        className={`pb-2
                                        w-full h-auto
                                        md:max-w-none md:h-auto
                                        lg:max-w-300px lg:max-h-400px
                                        xl:max-w-400px xl:max-h-500px
                                        hover:bg-gray-300`}>
                                        <Link
                                            to={`/events/${event.id}`}
                                            onClick={() => handleEventClick(event)}
                                            className="no-underline text-current text-left">
                                                <img
                                                    src={event.coverPhotoURL}
                                                    className='object-cover 
                                                    w-full h-200px md:rounded-md'
                                                    alt={`Event cover photo for ${event.name}`}>
                                                </img>
                                        
                                            <p className="px-0 m-0 font-semibold tracking-wider
                                                            sm:text-md">
                                                {truncateEventTitle(event.name)}
                                            </p>
                                            <p className="px-0 m-0 font-light tracking-wide font-gray-500
                                                            sm:text-sm md:text-md lg:text-l">
                                                {event.location} · {event.startDate} · {eventLength[event.id]} photos
                                            </p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                </div>
            </div>
        </div>
    );
};

export default UserEventList;
//   useEffect(() => {
//     // get list of eventIDs from event items
//     const eventIDs = userDynamoData.events.items.map((event) => {
//         return event.eventID;
//     });

//     const loadEvents = async () => {
//         try{
//             const fetchEventByID = async (eventID) => {
//                 console.log(eventID);
//                 const eventData = await API.graphql(graphqlOperation(queries.getEvent, { id: eventID }));
//                 return eventData.data.getEvent;
//               };
              
//             const events = await Promise.all(eventIDs.map(fetchEventByID));

//             setEvents(events);
//         } catch (error) {
//             console.log('error:', error);
//         }
//     };

//     if (events.length === 0) {
//         loadEvents();
//     }
//   }, [events]);
// if (events.length === 0) {
//     console.log('No events');
//     return (
//     <div className="flex flex-col items-center space-y-4 md:space-y-6">
//         <p>You have no events</p>
//     </div>
//     );
// }
