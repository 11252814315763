import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext'; 

const PrivateOutlet = () => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Show a loading spinner or similar
  }

  // Check if the path starts with /events/
  const isEventPath = location.pathname.startsWith('/events/');

  if (user) {
    return <Outlet />;
  }

  if (!user && isEventPath) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }

  // if (!user) {
  //     return <Navigate to="/login" />;
  // }

  // if (user) {
  //   return <Outlet />;
  // } else {
  //   return <Navigate to="/login" />;
  // }
};

export default PrivateOutlet;