import React from 'react';
import glassPic from '../../assets/images/glass.png';

function StartUpload({ onStartClick }) {
    return (
        <div className="min-h-screen flex flex-col items-center justify-top mt-10 overscroll-contain overflow-y-hidden">
            <div className="flex flex-col items-center justify-center md:w-500px lg:w-750px" > 
            {/* style={{'width':'500px'}} bg-white/20 */}
                <div className="mx-4  rounded flex flex-col items-center">
                    <img src={glassPic} alt="Description of Image" className="my-4" />
                    <div className="text-left m-2">
                        <h1 className="text-3xl font-bold mb-4">Hey there 👋 upload a<br />selfie to get started</h1>
                        {/* <p className="text-lg font-light mb-8 w-11/12">Telescope uses the most advanced facial recognition software to help you get your pictures</p> */}
                        <p className="font-normal text-lg mb-8 w-11/12">
                            Your selfie will be securely uploaded and used solely for comparison - It will not be shared or seen by anyone
                        </p>
                    </div>
                </div>
                <div className="px-4 md:px-8 m-4 w-full mx-4px">
                    <button
                        onClick={onStartClick} 
                        className="bg-sec-yellow text-black hover:bg-yellow-600 w-100 transition py-2 rounded">
                        Get started 
                        <span className="ml-2">
                            &rarr;  {/* This represents the arrow right */}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StartUpload;
