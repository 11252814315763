import React, { useState, useEffect }  from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

// import Gallery from 'react-photo-gallery';
// import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import '../../stylesheets/events/eventPage.css';

import { useUser } from '../../contexts/UserContext';
import { useEvent } from '../../contexts/EventContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as customQuery from '../../graphql/custom_queries';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faUser, faUsers, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';


const EventPage = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    // set state vars
    const [eventPhotos, setEventPhotos] = useState([]);
    const [CDNPhotos, setCDNPhotos] = useState([]);
    const [userOnlyPhotos, setUserOnlyPhotos] = useState([]);
    const [showUserOnly, setShowUserOnly] = useState(false);
    // get event details for current event from event context
    const { events, currentEvent, setCurrentEvent } = useEvent();
    const { userDynamoData } = useUser();
    const [isThreeColumn, setIsThreeColumn] = useState(true);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

    console.log("eventPhotos:", eventPhotos);
    console.log("userOnlyPhotos:", userOnlyPhotos);
    console.log("selectedPhotoIndex:", selectedPhotoIndex);

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures the effect runs only once when the component mounts
    
    
    const openModal = (index) => {
        if (index >= 0 && index < (showUserOnly ? userOnlyPhotos.length : eventPhotos.length)) {
            setSelectedPhotoIndex(index);
            setModalIsOpen(true);
        }
        console.log('Modal open');
    };

    const closeModal = () => {
        setModalIsOpen(false);
        console.log('Modal closed');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          setSelectedPhotoIndex((prev) => Math.max(prev - 1, 0));
        } else if (event.key === 'ArrowRight') {
          setSelectedPhotoIndex((prev) =>
            Math.min(prev + 1, (showUserOnly ? userOnlyPhotos.length : eventPhotos.length) - 1)
          );
        }
    };

    const handlePrevious = () => {
        setSelectedPhotoIndex((prev) => Math.max(prev - 1, 0));
      };
    
      const handleNext = () => {
        setSelectedPhotoIndex((prev) =>
          Math.min(prev + 1, (showUserOnly ? userOnlyPhotos.length : eventPhotos.length) - 1)
        );
      };
    
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedPhotoIndex, showUserOnly, userOnlyPhotos, eventPhotos]);
    
    

    const setThreeColumnGrid = () => {
        setIsThreeColumn(true);
    };
    const setVerticalScroll = () => {
        setIsThreeColumn(false);
    };

    const toggleUserPhotos = () => {
        setShowUserOnly((prev) => !prev);
    };

    const displayUserPhotos = () => {
        setShowUserOnly(true);
    };
    const displayAllPhotos = () => {
        setShowUserOnly(false);
    };

    // get event details for current event from event context
    const event = events.find((event) => event.id === eventId);
    console.log('event:', event);

    // store event photos in state
    useEffect(() => {
        if (event) {
            const eventPhotoIDs = fetchPhotosForEvent(event.id);
            // console.log('eventPhotoIDs:', eventPhotoIDs);
        }
    }
    , [event]);

    const handleBackClick = () => {
        navigate('/events');
    };

    // Fetch photos for a specific event
    const fetchPhotosForEvent = async (eventId) => {
        try {
            await API.graphql(graphqlOperation(customQuery.listPhotosWithUsers, {
                filter: {
                    eventID: {
                        eq: eventId
                    }
                }
            })).then(response => {
                console.log('response:', response);
                const photos = response.data.listPhotos.items;
                // console.log('photos:', photos);

                // modify url to use cloudfront
                photos.forEach(photo => photo.full_res_url = photo.full_res_url.replace('https://s3-us-west-2.amazonaws.com/', process.env.REACT_APP_CLOUDFRONT_URL));
                // try removing bucket name
                photos.forEach(photo => photo.full_res_url = photo.full_res_url.replace('testcoreapp-eventphotos/', ''));
                // console.log('Modified photos:', photos);

                const photoURLs = photos.map(photo => photo.full_res_url);
                console.log('photoURLs:', photoURLs);
                setCDNPhotos(photos);
                setEventPhotos(photos);
                
                // filter photos to only include those that belong to the current user
                const userPhotos = photos.filter(photo => photo.users.items.some(item => item.userID === userDynamoData.id));

                // const userPhotos = photos.filter(photo => photo.users.items.map(user => user.userID === userDynamoData.id));
                console.log('userPhotos:', userPhotos);
                setUserOnlyPhotos(userPhotos);

                return photos;
            }
            );
        } catch (error) {
            console.error("Error fetching photos for event:", error);
            return [];
        }
    }

    // Check if there's a currentEvent in the context
    if (!event) {
    return <div>event loading</div>;
    }

    return (
        <div className="pb-1 mx-auto min-h-screen bg-white bg-opactiy-50 text-darkgray
                        max-w-screen bg-white">
            
            <div className='max-w-screen-xl sm:w-full items-center mx-auto w-full px-4'>
                <button 
                    className='text-darkgray text-l hover:underline transition pt-2 pb-0 m-0 px-0 rounded' 
                    onClick={handleBackClick}> 
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>


                {/* <div className='my-4'> */}
                <p className="mb-0 pt-2 line-clamp-2 leading-tight font-semibold tracking-wide
                                sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl">{event.name}</p>
                <p className="m-0 line-clamp-1 leading-tight font-thin tracking-none
                                sm:text-sm md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">
                    {event.location} · {event.startDate}
                </p>
                <p className='m-0 line-clamp-1 leading-tight font-thin tracking-none
                                sm:text-sm md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl'> {event.description}</p>
                <p className="m-0 line-clamp-1 leading-tight font-thin tracking-none
                                sm:text-sm md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">Included in {userOnlyPhotos.length} / {eventPhotos.length} photos</p>
                {/* </div> */}

                <div className="flex justify-between mb-2 mt-2">
                    <div className="flex justify-between">
                        <button className={`sm:w-100px sm:text-xs ${showUserOnly ? 'border-b-2 border-white' : 'border-b-2 border-blue-500'}`}
                                onClick={displayAllPhotos}>
                            <FontAwesomeIcon icon={faUsers} /> All photos
                        </button>
                        <button className={`sm:w-100px sm:text-xs ${showUserOnly ? 'border-b-2 border-blue-500' : 'border-b-2 border-white'}`}
                                onClick={displayUserPhotos}>
                            <FontAwesomeIcon icon={faUser} /> My photos
                        </button>
                    </div>
                    <div className="flex justify-between">
                        <button className={`sm:w-75px ${isThreeColumn ? 'border-b-2 border-blue-500' : 'border-b-2 border-white'}`}
                                onClick={setThreeColumnGrid}>
                            <FontAwesomeIcon icon={faTh} size='' color='gray-500' />
                        </button>
                        <button className={`sm:w-75px ${isThreeColumn ? 'border-b-2 border-white' : 'border-b-2 border-blue-500'}`}
                                onClick={setVerticalScroll}>
                            <FontAwesomeIcon icon={faSquare} />
                        </button>
                    </div>
                </div>

                {isThreeColumn ? 
                <div className={`grid grid-cols-3
                                justify-center gap-0.1`}>
                    {(showUserOnly ? userOnlyPhotos : eventPhotos).map((photo, index) => (
                        <div key={photo.id} className="relative aspect-w-1 aspect-h-1">
                            <button onClick={() => openModal(index)} className="absolute inset-0"></button>
                            {/* <Link to={`/photo/${photo.id}`} className="absolute inset-0" photo={photo}></Link> */}
                            <div className='sm:h-125px md:h-56 lg:h-72 xl:h-96'>
                                <img
                                    src={photo.full_res_url}
                                    alt={photo.description}
                                    className="object-cover w-full h-full"
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    ))}
                </div> :
                <div className={`grid grid-cols-1
                                justify-center gap-3`}>
                    {(showUserOnly ? userOnlyPhotos : eventPhotos).map((photo, index) => (
                        <div key={photo.id} className="relative aspect-w-1 aspect-h-1">
                            <button onClick={() => openModal(index)} className="absolute inset-0"></button>
                            {/* <Link to={`/photo/${photo.id}`} className="absolute inset-0" photo={photo}></Link> */}
                            <div className='h-500px'>
                                <img
                                    src={photo.full_res_url}
                                    alt={photo.description}
                                    className="object-cover w-full h-full"
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    ))}
                </div>}
            </div>

            {/* Photo Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Photo Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div className="flex justify-center items-center h-full sm:p-7 md:m-20">
                    {selectedPhotoIndex > 0 && (
                        <button onClick={handlePrevious} className="absolute top-1/2 left-4 text-white text-3xl">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                    )}
                    <button onClick={closeModal} className="absolute top-4 right-4 text-white text-3xl">
                        &times;
                    </button>
                <div className="modal-photo-container sm:p-2">
                    <img
                    src={(showUserOnly ? userOnlyPhotos : eventPhotos)[selectedPhotoIndex]?.full_res_url}
                    alt={(showUserOnly ? userOnlyPhotos : eventPhotos)[selectedPhotoIndex]?.description}
                    className="modal-photo"
                    />
                </div>
                    {selectedPhotoIndex < (showUserOnly ? userOnlyPhotos.length : eventPhotos.length) - 1 && (
                        <button onClick={handleNext} className="absolute top-1/2 right-4 text-white text-3xl">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default EventPage;

{/* <img 
    src={event.coverPhotoURL} 
    className='w-full 
                sm:h-40 md:h-40 lg:h-80 xl:h-96 object-cover'
    alt={`Event cover photo for ${event.name}`}>
</img> */}
{/* <div className="mt-4 border-t border-gray-200"></div> */}
{/* <Masonry
    breakpointCols={breakpointColumnsObj}
    className="my-masonry-grid ml-[-5px]"
    columnClassName="my-masonry-grid_column">
    {(showUserOnly ? userOnlyPhotos : eventPhotos).map((photo) => (
        <div key={photo.id}>
            <Link to={`/photo/${photo.id}`} className="" photo={photo}>
            <img 
            src={photo.full_res_url} 
            alt={photo.description}
            loading='lazy'
            />
            </Link>
        </div>
    ))}
</Masonry> */}

{/* <Gallery photos={eventPhotos} />; */}

{/* sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 */}

{/* <button onClick={toggleGrid}>
    {isThreeColumn ? 'Switch to One Column' : 'Switch to Three Columns'}
</button>  */}