/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      phoneNumber
      profilePicture
      photos {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      identifiedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hostedEvents {
        items {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      eventID
      users {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      detectedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      full_res_url
      compressed_url
      description
      isUserFace
      hasUnidentifiedFaces
      isPublic
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $id: ID
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhotos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      hostID
      host {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      coverPhotoURL
      date
      startDate
      endDate
      photographer
      photos {
        items {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guests {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location
      description
      isPublic
      isPublished
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhotoUserJoin = /* GraphQL */ `
  query GetPhotoUserJoin($id: ID!) {
    getPhotoUserJoin(id: $id) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPhotoUserJoins = /* GraphQL */ `
  query ListPhotoUserJoins(
    $filter: ModelPhotoUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoUserJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserEventJoin = /* GraphQL */ `
  query GetUserEventJoin($id: ID!) {
    getUserEventJoin(id: $id) {
      id
      userID
      eventID
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserEventJoins = /* GraphQL */ `
  query ListUserEventJoins(
    $filter: ModelUserEventJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEventJoins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        eventID
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFace = /* GraphQL */ `
  query GetFace($id: ID!) {
    getFace(id: $id) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      boundingBox
      identified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFaces = /* GraphQL */ `
  query ListFaces(
    $id: ID
    $filter: ModelFaceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFaces(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        boundingBox
        identified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const photosByEventIDAndCreatedAt = /* GraphQL */ `
  query PhotosByEventIDAndCreatedAt(
    $eventID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photosByEventIDAndCreatedAt(
      eventID: $eventID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByHostIDAndName = /* GraphQL */ `
  query EventsByHostIDAndName(
    $hostID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByHostIDAndName(
      hostID: $hostID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const photoUserJoinsByPhotoIDAndUserID = /* GraphQL */ `
  query PhotoUserJoinsByPhotoIDAndUserID(
    $photoID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photoUserJoinsByPhotoIDAndUserID(
      photoID: $photoID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const photoUserJoinsByUserIDAndPhotoID = /* GraphQL */ `
  query PhotoUserJoinsByUserIDAndPhotoID(
    $userID: ID!
    $photoID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoUserJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photoUserJoinsByUserIDAndPhotoID(
      userID: $userID
      photoID: $photoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userEventJoinsByUserIDAndEventID = /* GraphQL */ `
  query UserEventJoinsByUserIDAndEventID(
    $userID: ID!
    $eventID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserEventJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userEventJoinsByUserIDAndEventID(
      userID: $userID
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        eventID
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userEventJoinsByEventIDAndUserID = /* GraphQL */ `
  query UserEventJoinsByEventIDAndUserID(
    $eventID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserEventJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userEventJoinsByEventIDAndUserID(
      eventID: $eventID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        eventID
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facesByPhotoIDAndUserID = /* GraphQL */ `
  query FacesByPhotoIDAndUserID(
    $photoID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facesByPhotoIDAndUserID(
      photoID: $photoID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        boundingBox
        identified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const facesByUserIDAndPhotoID = /* GraphQL */ `
  query FacesByUserIDAndPhotoID(
    $userID: ID!
    $photoID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    facesByUserIDAndPhotoID(
      userID: $userID
      photoID: $photoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        photoID
        userID
        photo {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        boundingBox
        identified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
