import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Modal from 'react-modal';
import 'bootstrap/dist/css/bootstrap.css';
import { Amplify, Auth, Storage } from 'aws-amplify';
import config from './aws-exports';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(config);
Auth.configure(config);
Storage.configure(config);

// Set the root element for the modal (replace '#root' with your root element selector)
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);