/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://uwdwrlwtqrh7jcc577rttgsfaa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3uqg2bcjdnd3xmop4nxlneclj4",
    "aws_cognito_identity_pool_id": "us-west-2:c97cee5a-4f45-400d-a466-500dba76fbbf",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_PiicbUjWi",
    "aws_user_pools_web_client_id": "6io7pkf52a5g282l8ivh636s59",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
