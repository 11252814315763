import React, { useState, useEffect }  from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useEvent } from '../../contexts/EventContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as customQuery from '../../graphql/custom_queries';

const EventPage = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    
    // set state vars
    const [event, setEvent] = useState(null);
    const [eventPhotos, setEventPhotos] = useState([]);
    const [shownPhotos, setShownPhotos] = useState([]);
    const [blurredPhotos, setBlurredPhotos] = useState([]);

    // store event photos in state
    useEffect(() => {
        console.log('Event ID:', eventId);
        console.log(event);
        if (eventId && !event) {
            console.log('Fetching event:', eventId);
            fetchEvent(eventId);
        }
    }
    , [event]);

    // Store the first visited event page URL in local storage
    useEffect(() => {
        localStorage.setItem('firstVisitedEventPage', window.location.href);
      }, []);

    // Fetch event details 
    const fetchEvent = async (eventId) => {
        try {
            await API.graphql(graphqlOperation(queries.getEvent, 
                { 
                    id: eventId 
                })).then(response => {
                    console.log('response:', response);
                    const event = response.data.getEvent;
                    console.log('event:', event);
                    setEvent(event);
                    fetchPhotosForEvent(eventId);
                });
        }
        catch (error) {
            console.error("Error fetching event:", error);
        }
    }


    // Fetch photos for a specific event
    const fetchPhotosForEvent = async (eventId) => {
        try {
            await API.graphql(graphqlOperation(customQuery.listPhotosWithUsers, {
                filter: {
                    eventID: {
                        eq: eventId
                    }
                }
            })).then(response => {
                console.log('response:', response);
                const photos = response.data.listPhotos.items;
                console.log('photos:', photos);

                // modify url to use cloudfront
                photos.forEach(photo => photo.full_res_url = photo.full_res_url.replace('https://s3-us-west-2.amazonaws.com/', 'https://d2mtzqab0pa86m.cloudfront.net/'));
                // try removing bucket name
                photos.forEach(photo => photo.full_res_url = photo.full_res_url.replace('testcoreapp-eventphotos/', ''));


                const photoURLs = photos.map(photo => photo.full_res_url);
                
                // console.log('photoURLs:', photoURLs);
                setEventPhotos(photos);
                
                // Split photos
                setShownPhotos(photos.slice(0, 3));
                console.log('shownPhotos:', shownPhotos);

                setBlurredPhotos(photos.slice(3,9));

                return photos;
            }
            );
        } catch (error) {
            console.error("Error fetching photos for event:", error);
            return [];
        }
    }

    // Check if there's a currentEvent in the context
    if (!event) {
    return <div>event loading</div>;
    }

    return (
        <div className="min-h-screen bg-white bg-opactiy-50 text-darkgray max-w-screen bg-white">
            {/* <img 
                src={event.coverPhotoURL} 
                className='w-full h-48 object-cover'
                alt={`Event cover photo for ${event.name}`}>
            </img>
            <div className='mx-4'>
                <h1 className="text-2xl font-semibold mb-2 h-16">{event.name}</h1>
                <p className="mb-2 h-4">{event.date}</p>
                <p className="mt-3 font-light h-10">{event.description}</p>
            </div> */}
            
            {/* <div className="mt-4 border-t border-gray-200"></div> */}
            <div className='max-w-screen-xl items-center w-full mx-auto relative'>
                <div className='px-4'>
                    <h1 className="sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl 
                                            mb-2 pt-2">{event.name}</h1>
                    <p className="mb-2
                                    sm:text-l md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">{event.startDate}</p>
                    <p className="mt-3 font-light
                                    sm:text-l md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">{event.description}</p>
                </div>

                <div className="grid grid-cols-3 items-center justify-center gap-0.1">
                    {shownPhotos.map((photo) => (
                        <div key={photo.id} className="relative h-125px md:h-56 lg:h-72 xl:h-96">
                            <img src={photo.full_res_url} alt={photo.description} className="object-cover w-full h-full" loading='lazy'/>
                        </div>
                    ))}    

                    {blurredPhotos.map((photo) => (
                        <div key={photo.id} className="relative h-125px md:h-56 lg:h-72 xl:h-96">
                            {/* Blurred photo */}
                            <img src={photo.full_res_url} alt={photo.description} className="object-cover w-full h-full blur" loading='lazy'/>
                        </div>
                    ))}
                </div>
            
                <div className="bg-black bg-opacity-75 absolute 
                                flex flex-col w-full items-center justify-center text-white z-1
                                top-sm_unauth_top h-500px
                                md:top-md_unauth_top lg:top-lg_unauth_top xl:top-xl_unatuh_top">
                    <p className="z-1 mx-4 mb-4 text-white text-center text-xl">
                        Log in or create an account to view all event photos
                    </p>

                    <Link to="/login" className="z-1 w-2/3 py-2 bg-sec-yellow text-black rounded-lg font-semibold text-center no-underline hover:text-2xl">
                        Log in
                    </Link>

                    <Link to="/signup" className="z-1 w-2/3 my-3 text-sec-yellow rounded-lg text-center no-underline hover:text-2xl font-light">
                        Create an account
                    </Link>
                </div>

            </div>
        </div>
        
    );
};

export default EventPage;