import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../firebase-config';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { useAuthState } from 'react-firebase-hooks/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../contexts/UserContext';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from "react-otp-input";
import "../../stylesheets/auth/VerificationPage.css";

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

function LoginPage({ isSignup }) {
  const navigate = useNavigate();
  const { user, userDynamoData, loading, setLoading, error } = useUser();
  // State variables
  const [phoneNumber, setPhoneNumber] = useState("");
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [OTP, setOTP] = useState("");
  const [expandForm, setExpandForm] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);

  const [key, setKey] = useState(0);

  // verify OTP if all numbers entered
  useEffect(() => {
    if (OTP.length === 6) {
      verifyOTP(OTP);
    }
  }, [OTP]);

  useEffect(() => {
    if (isCodeVerified && !loading) {
      console.log('Quick check of userDynamoData:', userDynamoData);
      if (userDynamoData === null) {
        console.log('Dynamo User being created... Routing to name entry / webcam flow');
        navigate('/selfieUpload');
      } else if (userDynamoData.profilePicture === null || 
                userDynamoData.profilePicture === undefined ||
                userDynamoData.profilePicture === "" ) {
        console.log('User has no profile picture. Routing to webcam start page');
        navigate('/selfieUpload');
      } else if (userDynamoData.name === null || 
        userDynamoData.name === undefined ||
        userDynamoData.name === "" ) {
        console.log('User has no name, routing to name entry...');
        navigate('/nameEntry');
      } else if (localStorage.getItem('firstVisitedEventPage')) {
        let item = localStorage.getItem('firstVisitedEventPage');
        console.log('firstVisited Event Page: ',item);

        item = item.replace('http://localhost:3000','');
        item = item.replace('localhost:3000','');
        item = item.replace('https://www.trytelescopeapp.com/','');

        console.log('Event path: ', item);

        navigate(item);
      } else {
        console.log('User has profile picture. Routing to event list');
        navigate('/events');
      }

      setIsCodeVerified(false); // Reset otpVerified state to false after routing
    }
 }, [isCodeVerified, userDynamoData, navigate]);

   // Redirect to the homepage if the user is already logged in
   useEffect(() => {
    if(user & userDynamoData) {
      navigate('/');  
      console.log('Redirect - logged in');
    }
  }, [user, navigate]);

  
  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
      }
    }, auth);
  }

  const handleChange = (value, country, e, formattedValue) => {
    setPhoneNumber(formattedValue);
  };

  const requestOTP = (e) => {
    e.preventDefault();
    console.log('Requesting OTP for phone number:', phoneNumber);

    if (phoneNumber.length === 17) {
      let areaCode = phoneNumber.slice(4,7);  // Extract the area code
      let lastFour = phoneNumber.slice(-4);    // Extract the last four digits
      let maskedPhoneNumber = `+1 (${areaCode}) ***-${lastFour}`;
      setMaskedPhoneNumber(maskedPhoneNumber);
      setExpandForm(true);
      setIsCodeSent(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      // Send OTP to user's phone
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          setConfirmationResult(confirmationResult);
        }).catch((error) => {
          console.log(error);
        });
    }
  }

  const handleKeypress = e => {
    // Enter key pressed
      // validate phone number before requesting OTP
    if (phoneNumber.length === 17 && e.keyCode === 13) {
      requestOTP(e);
    }
  };

  const verifyOTP = (e) => {
    console.log('OTP is 6 characters long. Verifying OTP');
    confirmationResult?.confirm(OTP)
    .then(async (result) => {
      console.log('Verified. User:', result.user);
      setIsCodeVerified(true);
    }).catch((error) => {
      // console.log(error);
      setIsWrongCode(true);
      console.log('Wrong code entered');
      setOTP('');
      setKey(key + 1);
      // Optionally, add a timeout to hide the error message after some time
      setTimeout(() => setIsWrongCode(false), 5000);
    });
    
  } 


  return (

    <div className="bg-regal-blue flex min-h-full flex-1 flex-col justify-left py-12 lg:px-8">

    { isCodeSent === false?
      <>
        <div className="mx-auto w-screen max-w-sm xl:w-800px lg:w-800px">
          <h1 className="font-normal tracking-tight text-center
                        sm:text-3xl
                        md:text-4xl
                        lg:text-5xl
                        xl:text-6xl">
            {isSignup ? 'Create your account' : 'Sign in to your account'}
          </h1>
        </div>

        <div 
          className="mt-10 flex justify-center items-center
                    sm:mx-auto sm:w-full sm:max-w-sm
                    xs:mx-auto max-w-screen">
          <form 
            className="space-y-6"
            onSubmit={requestOTP}>
            <div className='items-center justify-center'>
              {/* <label 
                htmlFor="PhoneInput"
                className="form-label">Phone Number</label> */}
              <PhoneInput
                onlyCountries={['us']}
                country={'us'}
                value={phoneNumber}
                disableDropdown={true}
                onChange={handleChange}
                onKeyDown={(e) => handleKeypress(e)}
              />
              <button 
                className='bg-sec-yellow hover:bg-yellow-600 transition text-black w-[300px] py-1 my-4 rounded
                            disabled:hover:bg-sec-yellow disabled:cursor-not-allowed' 
                onClick={requestOTP}
                type="submit"
                disabled={phoneNumber.length < 17}> Send Code
              </button>    
            </div>
          </form>
        </div>
      </> :
      <>
        <div 
          className="flex justify-center items-center">
            <div>
              <h1 className="mt-10 text-left text-5xl font-normal leading-9 tracking-tight">
                  Check your text
              </h1>
              <br></br>
              <h6> We just sent a verification code to <br></br> {maskedPhoneNumber}</h6>
              <br></br>
              <p
                className='m-0 pb-3'>Enter the 6 digit code below
              </p>
              <div className='w-72'>
                <OtpInput
                  key={key}
                  value={OTP}
                  onChange={setOTP}
                  numInputs={6}
                  inputType="tel"
                  inputStyle="otpInputStyle"
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} type="tel"/>}
                />
              </div>
              {isWrongCode && (
                  <div className="bg-red-100 bg-opacity-75 border border-red-400 text-red-700 px-4 py-3 my-3 rounded relative" role="alert">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                    <strong className="font-bold">Error! </strong>
                    <span className="block sm:inline">Incorrect OTP entered.</span>
                  </div>
              )}
              <div className='flex justify-center'>
                <button 
                    className='text-sec-yellow
                                pt-5
                                hover:underline' 
                    onClick={requestOTP}
                    type="submit"
                    disabled={phoneNumber.length < 17}> Resend Code
                  </button>   
              </div>
              <div className='flex justify-center'>
                  <button 
                    className="text-sec-yellow hover:underline transition w-[50px] 
                                py-10 rounded transform"
                    onClick={() => setIsCodeSent(false)}> 
                    Back
                </button>
              </div>
          </div>
        </div>
      </>
    }
      <div id="recaptcha-container"></div>
    </div>    
  )
}
   
export default LoginPage;