import React, { useState } from 'react';
import WebcamComponent from './Webcam';
import StartUpload from './StartSelfie';

function UploadSelfie() {
    const [isTakingPicture, setIsTakingPicture] = useState(false);

    const handleStartClick = () => {
        setIsTakingPicture(prevState => !prevState);
    };

    return isTakingPicture ? <WebcamComponent onStartClick={handleStartClick} /> : <StartUpload onStartClick={handleStartClick}/>;
}

export default UploadSelfie;
