import React, { useState, useEffect }  from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useUser } from '../../contexts/UserContext';
import { useEvent } from '../../contexts/EventContext';

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const PhotoDisplay = () => {
    const navigate = useNavigate();

    const { photoId } = useParams();
    
    const [photo, setPhoto] = useState(null);


    useEffect(() => {
        if (photoId) {
            const photo = fetchPhoto(photoId);
        }
    }
    , [photoId]);

    const fetchPhoto = async (photoId) => {
        if(!photo) {
            try {
                await API.graphql(graphqlOperation(queries.getPhoto, { id: photoId }))
                    .then(response => {
                        console.log('response:', response);
                        const photo = response.data.getPhoto;
                        setPhoto(photo);
                    });
            } catch (error) {
                console.log('error on fetching photo details', error);
            }
        } else
        return photo;  // return photo details from state if it exists already.  Otherwise, fetch photo details from API and return.  This is to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the same photo.  Photo details are stored in state to prevent multiple API calls for the
    }

    console.log('Photo URL:', photoId);

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className='bg-black bg-opacity-90 h-screen'>
            <button 
                className='text-white hover:underline transition w-[50px] p-4 rounded' 
                onClick={handleBackClick}> Back
            </button>
            {photo &&
                <div className='align-middle mx-auto my-auto
                                sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/3'
                    alt={`Event cover photo for ${photo.name}`}>
                    <h1 className='text-white text-center text-2xl font-semibold'>{photo.name}</h1>
                    <img 
                        className="w-full h-full"
                        src={photo.full_res_url}  />
                </div>
            }
        </div>
    );
};

export default PhotoDisplay;
