import logo from './logo.svg';
import './App.css';
import { useAuthenticator, withAuthenticator, Authenticator, Heading, View, Image, useTheme, Text  } from '@aws-amplify/ui-react';
import { useState, useEffect, useContext, createContext } from 'react';
import { Storage, Auth, graphqlOperation, API } from 'aws-amplify';
import { RekognitionClient, AssociateFacesCommand } from "@aws-sdk/client-rekognition";
import '@aws-amplify/ui-react/styles.css';
// import function to read from User table in DynamoDB
import * as queries from "./graphql/queries";
import AWS from 'aws-sdk';
import {Route, Routes} from 'react-router-dom';
import PrivateOutlet from './components/routes/ProtectedRoute';

import SelfieUploadWrapper from './components/SelfieUpload/SelfieUploadWrapper.js';
import SiteFooter from './components/common/SiteFooter';
import SiteNav from './components/common/SiteNav.js';
import HomePage from './components/home/homepage';
import NameEntry from './components/NameEntry/NameEntryPage';
import EnterNameWrapper from './components/NameEntry/NameEntryWrapper';
import LoginPage from './components/auth/FireLoginPage';
import EventPhotoList from './components/events/EventPhotoList';
import EventPhotoListWrapper from './components/events/EventWrapper';
import UserEventsList from './components/events/UserEventsList';
import PhotoDisplay from './components/events/PhotoDisplay';
import EventsScroll from './components/events/EventsScroll.js';
import { auth } from './firebase-config';
import app from './firebase-config';

import { UserProvider } from './contexts/UserContext';
import { EventProvider } from './contexts/EventContext';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET
});

function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(null);

  // function updateAuthStatus(authStatus) {
  //   setIsAuthenticated(authStatus);
  // }

  return (
  //   <div className="appContainer">
  //     <Authenticator loginMechanisms={["phone_number"]} components={components}>
  //     {({ signOut, user }) => (
  //       <div>
  //         <SiteNav logOut={signOut} user={user}/>
  //         <Routes>
  //           <Route path="*" element={<HomePage user={user} />} />
  //           <Route path="/" exact={true} element={<HomePage user={user} />} />
  //           <Route path="/photos" element={<Photos user={user} />} />
  //           <Route path="/photosNew" element={<UserPhotosComp user={user} />} />
  //           <Route path="/events" element={<Events user={user} />} />
  //           <Route path="/createEvent" element={<EventsForm user={user} />} />
  //           <Route path="/events/:eventId" element={<EventPage user={user} />} />
  //           <Route path="/webcam" element={<WebcamComponent user={user} />} />
  //         </Routes>
  //         <SiteFooter />
  //       </div>
  //     )}
  //    </Authenticator>
  // </div>
  

  <UserProvider>
    <EventProvider>
      <div className="bg-regal-blue text-white min-h-screen font-sans">
        <SiteNav  />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<LoginPage isSignup={true} />} />
          {/* Protected Routes */}
          <Route path="/" element={<PrivateOutlet />}>
            <Route index element={<UserEventsList />} />
            <Route path="*" element={<UserEventsList />} />
            <Route path="nameEntry" element={<NameEntry />} />
            <Route path="selfieUpload" element={<SelfieUploadWrapper />} />
            <Route path="events" element={<UserEventsList />} />
            <Route path="eventSroll" element={<EventsScroll />} />
            <Route path="events/:eventId" element={<EventPhotoListWrapper />} />
            <Route path="photo/:photoId" element={<PhotoDisplay />} />
          </Route>
        </Routes>
        {/* <SiteFooter /> */}
      </div>
    </EventProvider>
  </UserProvider>
  );
}

export default App;