import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../firebase-config';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { useAuthState } from 'react-firebase-hooks/auth';

import { useUser } from '../../contexts/UserContext';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from "react-otp-input";
import "../../stylesheets/auth/VerificationPage.css";

import { graphqlOperation, API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

function NameEntry() {
    const navigate = useNavigate();
    const { user, userDynamoData, loading, setLoading, error } = useUser();
    // State variables
    const [name, setName] = useState('');

    // update user name attirbute in dynamo db
    const updateName = async () => {
        console.log('Updating name...');

        const updateUserInput = {
            id: userDynamoData.id,
            name: name
        }

        console.log()

        try {
            console.log('trying update user');
            const result = await API.graphql(graphqlOperation(mutations.updateUser, { input: updateUserInput }));
            console.log('data from updateUser: ', result);

            // route to specific event if firstVisitedEventPage present in localStorage
            if (localStorage.getItem('firstVisitedEventPage')) {
            const item = localStorage.getItem('firstVisitedEventPage');
            console.log('firstVisited Event Page: ',item);

            item = item.replace('http://localhost:3000','');
            item = item.replace('localhost:3000','');
            item = item.replace('https://www.trytelescopeapp.com/','');
    
            console.log('Event path: ', item);
            
        } else {
            navigate('/events');
        }

        } catch (err) {
            console.log('error from updateUser: ', err);
        }
    }


    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return (

        <div className="bg-regal-blue flex min-h-full flex-1 flex-col justify-left px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h1 className="mt-10 text-left text-3xl font-normal leading-9 tracking-tight">
                Quick question before showing you your photos...
                <br></br>
                <br></br>
                What is your name?
                </h1>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
                <input
                    type="text"
                    placeholder="Your beautiful name :)"
                    className="w-300px h-50px bg-white bg-opacity-10 p-4 text-2xl rounded"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />

                <button className='bg-sec-yellow text-black hover:bg-yellow-600 transition w-[300px] py-1 my-4 rounded disabled:bg-opacity-50 disabled:cursor-not-allowed'
                    type="submit"
                    disabled={!name}
                    onClick={updateName}> Continue
                </button>
            </div>
        </div>    
    )
}
   
export default NameEntry;