// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { auth } from '../../firebase-config';
import { useUser } from '../../contexts/UserContext';
import { useEvent } from '../../contexts/EventContext';
import { useNavigate, Link, NavLink, useLocation } from 'react-router-dom';

function SiteNav(props) {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('Pathname: ',location.pathname);
    const { user, loading, error, userDynamoData } = useUser();
    const { events, currentEvent, setCurrentEvent } = useEvent();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [isSignupPage, setIsSignupPage] = useState(false);
    
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [allEvents, setAllEvents] = useState([]);


    useEffect(() => {
        setAllEvents(events ? events : []);
        console.log('All events: ', allEvents);
        if (searchTerm === '') {
            setFilteredResults([]);
        } else {
            setFilteredResults(allEvents.filter(event =>
                event.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
            console.log('Filtered results: ', filteredResults);
        }
    }, [searchTerm, allEvents]);

    useEffect(() => {
        const closeDropdown = (e) => {
            // Check if the clicked target is not within the dropdown menu
            if (!e.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('click', closeDropdown);

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    // // check if user is uploading a picture (/selfieUpload is location)
    // useEffect(() => {
    //     if (location.pathname == '/selfieUpload' |
    //         location.pathname == '/nameentry' ) {
    //         setIsSignupPage(true);
    //         console.log('Signup Page:', isSignupPage);
    //     }
    // }
    // , [location.pathname]);

    // Determine whether the current location is the signup page
    const isSignupPage = location.pathname === '/selfieUpload' || location.pathname === '/nameEntry';
    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const isUserLoggedIn = user ? true : false;

    const handleLogout = async () => {
        try {
            await auth.signOut();
            // Remove first visited event page from local storage
            localStorage.removeItem('firstVisitedEventPage');
            // Redirect to homepage or login after logout
            navigate('/login'); 
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    };


    return (
        <>
            {isUserLoggedIn && !isSignupPage ? (
                <nav className="w-full top-0 left-0 border-b border-gray-200 bg-white dark:border-gray-600 flex items-center h-16">
                    <div className="max-w-screen-xl flex items-center justify-between mx-auto w-full px-4">
                        <a href="/" className="flex items-center no-underline">
                            <span className="text-2xl tracking-none font-semibold whitespace-nowrap no-underline underline-none text-regal-blue">Telescope</span>
                        </a>
                        <div className="flex-grow mx-4 relative">
                            <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                                    <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search events"
                                className="w-full pl-7 py-2 
                                            border border-gray-300 
                                            rounded-lg 
                                            text-gray-600
                                            focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent"
                                onChange={handleSearch}
                            />
                            {/* Search Results */}
                            {filteredResults.length > 0 && (
                                <div className="absolute w-full mt-1 rounded-md shadow-lg bg-white bg-opacity-75">
                                    {filteredResults.map((event, index) => (
                                        <a key={index} href={`/events/${event.id}`} className="block px-4 py-2 text-gray-600 
                                                                                                no-underline hover:bg-gray-100 hover:text-blue-500">
                                            {event.name}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                        

                        <div className="relative">
                            <button 
                                onClick={(e) => {
                                    e.stopPropagation();  // Prevent the click event from bubbling up
                                    setDropdownOpen(!dropdownOpen);
                                }} 
                                className="focus:outline-none text-gray-800 dark:text-white">
                                {dropdownOpen ? (<FontAwesomeIcon icon={faBars} size='lg' className="text-blue-800" />) : (<FontAwesomeIcon icon={faBars} size='lg' className="text-regal-blue" />)}
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white text-black shadow-lg rounded-lg">
                                    {/* <a href="#" className="block px-4 py-2 no-underline hover:text-blue-800 hover:bg-gray-400">My events</a>
                                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:text-blue-800">Logout</button> */}
                                    {/* <a href="#" className=" block px-4 py-2 no-underline hover:bg-blue-500 hover:text-white">My events</a> */}
                                    {/* Using NavLink for active link styling */}
                                    <NavLink 
                                        to="/events"
                                        className="block px-4 py-2 no-underline text-darkgray hover:bg-blue-500 hover:text-white hover:text-lg"
                                        activeclassname="text-blue-200 bg-gray-400 hover:bg-blue-800 hover:bg-blue-200 hover:text-white"
                                        isactive='/events'
                                    >
                                        My events
                                    </NavLink>
                                    <button 
                                        onClick={handleLogout} 
                                        className="block w-full text-left  text-darkgray px-4 py-2 hover:bg-blue-500 hover:text-white hover:text-lg"
                                    >
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            ) : (
                <nav className="w-full top-0 left-0 border-b border-gray-200 dark:border-gray-600 flex items-center h-20 md:h-24">
                   <div className="max-w-screen-xl flex items-center mx-auto w-full px-4 justify-center">
                        <a href="/" className="flex items-center no-underline">
                            <span className="text-2xl lg:text-3xl font-semibold whitespace-nowrap no-underline underline-none text-white">Telescope</span>
                        </a>
                   </div>
               </nav>
            )}
        </>
    );
};

export default SiteNav;