import Container from 'react-bootstrap/Container';

function SiteFooter() {
    return (
        <footer className="py-5 my-5">
            <Container className='px-4'>
                <p className="text-end">AWS Rekognition</p>
            </Container>
        </footer>
    )
}

export default SiteFooter;