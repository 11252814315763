/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      profilePicture
      photos {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      identifiedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hostedEvents {
        items {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      profilePicture
      photos {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      identifiedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hostedEvents {
        items {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      phoneNumber
      profilePicture
      photos {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      identifiedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hostedEvents {
        items {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      eventID
      users {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      detectedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      full_res_url
      compressed_url
      description
      isUserFace
      hasUnidentifiedFaces
      isPublic
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      eventID
      users {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      detectedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      full_res_url
      compressed_url
      description
      isUserFace
      hasUnidentifiedFaces
      isPublic
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      eventID
      users {
        items {
          id
          photoID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      detectedFaces {
        items {
          id
          photoID
          userID
          boundingBox
          identified
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      full_res_url
      compressed_url
      description
      isUserFace
      hasUnidentifiedFaces
      isPublic
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      hostID
      host {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      coverPhotoURL
      date
      startDate
      endDate
      photographer
      photos {
        items {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guests {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location
      description
      isPublic
      isPublished
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      hostID
      host {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      coverPhotoURL
      date
      startDate
      endDate
      photographer
      photos {
        items {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guests {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location
      description
      isPublic
      isPublished
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      hostID
      host {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      name
      coverPhotoURL
      date
      startDate
      endDate
      photographer
      photos {
        items {
          id
          eventID
          full_res_url
          compressed_url
          description
          isUserFace
          hasUnidentifiedFaces
          isPublic
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guests {
        items {
          id
          userID
          eventID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location
      description
      isPublic
      isPublished
      isArchived
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPhotoUserJoin = /* GraphQL */ `
  mutation CreatePhotoUserJoin(
    $input: CreatePhotoUserJoinInput!
    $condition: ModelPhotoUserJoinConditionInput
  ) {
    createPhotoUserJoin(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePhotoUserJoin = /* GraphQL */ `
  mutation UpdatePhotoUserJoin(
    $input: UpdatePhotoUserJoinInput!
    $condition: ModelPhotoUserJoinConditionInput
  ) {
    updatePhotoUserJoin(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePhotoUserJoin = /* GraphQL */ `
  mutation DeletePhotoUserJoin(
    $input: DeletePhotoUserJoinInput!
    $condition: ModelPhotoUserJoinConditionInput
  ) {
    deletePhotoUserJoin(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserEventJoin = /* GraphQL */ `
  mutation CreateUserEventJoin(
    $input: CreateUserEventJoinInput!
    $condition: ModelUserEventJoinConditionInput
  ) {
    createUserEventJoin(input: $input, condition: $condition) {
      id
      userID
      eventID
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserEventJoin = /* GraphQL */ `
  mutation UpdateUserEventJoin(
    $input: UpdateUserEventJoinInput!
    $condition: ModelUserEventJoinConditionInput
  ) {
    updateUserEventJoin(input: $input, condition: $condition) {
      id
      userID
      eventID
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserEventJoin = /* GraphQL */ `
  mutation DeleteUserEventJoin(
    $input: DeleteUserEventJoinInput!
    $condition: ModelUserEventJoinConditionInput
  ) {
    deleteUserEventJoin(input: $input, condition: $condition) {
      id
      userID
      eventID
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        hostID
        host {
          id
          name
          phoneNumber
          profilePicture
          createdAt
          updatedAt
          __typename
        }
        name
        coverPhotoURL
        date
        startDate
        endDate
        photographer
        photos {
          nextToken
          __typename
        }
        guests {
          nextToken
          __typename
        }
        location
        description
        isPublic
        isPublished
        isArchived
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFace = /* GraphQL */ `
  mutation CreateFace(
    $input: CreateFaceInput!
    $condition: ModelFaceConditionInput
  ) {
    createFace(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      boundingBox
      identified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFace = /* GraphQL */ `
  mutation UpdateFace(
    $input: UpdateFaceInput!
    $condition: ModelFaceConditionInput
  ) {
    updateFace(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      boundingBox
      identified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFace = /* GraphQL */ `
  mutation DeleteFace(
    $input: DeleteFaceInput!
    $condition: ModelFaceConditionInput
  ) {
    deleteFace(input: $input, condition: $condition) {
      id
      photoID
      userID
      photo {
        id
        event {
          id
          hostID
          name
          coverPhotoURL
          date
          startDate
          endDate
          photographer
          location
          description
          isPublic
          isPublished
          isArchived
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        eventID
        users {
          nextToken
          __typename
        }
        detectedFaces {
          nextToken
          __typename
        }
        full_res_url
        compressed_url
        description
        isUserFace
        hasUnidentifiedFaces
        isPublic
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        name
        phoneNumber
        profilePicture
        photos {
          nextToken
          __typename
        }
        identifiedFaces {
          nextToken
          __typename
        }
        events {
          nextToken
          __typename
        }
        hostedEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      boundingBox
      identified
      createdAt
      updatedAt
      __typename
    }
  }
`;
